<template>
    <q-page>
        <div class="row q-py-xl">
            <div class="col desktop-only"></div>
            <div class="col-lg-7">
                <q-input label="Blog name" filled square v-model="name" class="q-mb-md" />
                <q-input label="Date" filled square v-model="date" class="q-mb-md" />
                <q-editor
                    v-model="body"
                    class="q-mb-md"
                    :dense="$q.screen.lt.md"
                    :toolbar="[
                        [
                            {
                                label: $q.lang.editor.align,
                                icon: $q.iconSet.editor.align,
                                fixedLabel: true,
                                list: 'only-icons',
                                options: ['left', 'center', 'right', 'justify'],
                            },
                        ],
                        ['hr', 'link'],
                        ['fullscreen'],
                        [
                            {
                                label: $q.lang.editor.formatting,
                                icon: $q.iconSet.editor.formatting,
                                list: 'no-icons',
                                options: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'code'],
                            },
                            {
                                label: $q.lang.editor.fontSize,
                                icon: $q.iconSet.editor.fontSize,
                                fixedLabel: true,
                                fixedIcon: true,
                                list: 'no-icons',
                                options: [
                                    'size-1',
                                    'size-2',
                                    'size-3',
                                    'size-4',
                                    'size-5',
                                    'size-6',
                                    'size-7',
                                ],
                            },
                            'removeFormat',
                        ],
                        ['quote', 'unordered', 'ordered', 'outdent', 'indent'],

                        ['undo', 'redo'],
                        ['viewsource'],
                    ]"
                />
                <q-btn
                    label="Parse JSON"
                    color="primary"
                    class="adicon-font-2 text-bold"
                    @click="escapeJSON()"
                />
            </div>
            <div class="col desktop-only"></div>
        </div>
    </q-page>
</template>

<script>
export default {
    data() {
        return {
            name: '',
            date: '',
            body: '',
            escapedBody: '',
        }
    },
    methods: {
        escapeJSON() {
            this.escapedBody = this.body.replace(/"/g, `\\"`)
            let blogJSON = `{ "name": "${this.name}", "date": "${this.date}", "body": "${this.escapedBody}"}`
            navigator.clipboard.writeText(blogJSON).then(
                () => {
                    alert('Copiado')
                },
                function(err) {
                    console.error('Async: Could not copy text: ', err)
                }
            )
        },
    },
}
</script>
