<template>
    <q-page>
        <!-- HERO -->
        <section id="inicio">
            <div class="row text-center adicon-hero items-center">
                <div class="col adicon-font-2 text-bold">
                    <h1 class="text-h3 text-bold text-white q-mb-lg desktop-only">
                        Administración Integral<br />de Condominios de Panamá
                    </h1>
                    <div class="text-h4 text-bold text-white q-mb-lg mobile-only">
                        Administración Integral de Condominios de Panamá
                    </div>
                    <q-btn
                        outline
                        color="white"
                        class="text-bold q-px-md q-py-xs"
                        label="conócenos"
                        href="#nuestra-mision"
                        v-smooth-scroll
                    />
                </div>
            </div>
        </section>
        <!-- END HERO -->

        <!-- SERVICES -->
        <section id="servicios">
            <div class="row q-py-xl">
                <div class="col desktop-only"></div>
                <div class="col-lg-10 q-pa-md">
                    <div class="row q-mb-lg">
                        <div class="col">
                            <div class="text-h4 adicon-font-2 text-bold text-center q-mb-sm ">
                                Nuestros Servicios
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-3 text-center q-px-md q-my-md">
                            <div class="service-tile">
                                <i class="fas fa-people-arrows fa-3x text-primary q-mb-lg"></i>
                                <div class="text-h6 adicon-font-1 text-bold q-mb-sm">
                                    Gestión del personal
                                </div>
                                <q-separator
                                    color="secondary"
                                    style="width: 100px; margin: 0 auto; height: 2px"
                                />
                                <div class="text-body adicon-font-1 text-grey-7 q-mt-md">
                                    Nuestro personal administrativo se encargara de la gestión,
                                    búsqueda, supervisión y control del recurso humano idóneo para
                                    cubrir cada una de las necesidades del edificio.
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3  text-center q-px-md q-my-md">
                            <div class="service-tile">
                                <i class="fas fa-tools fa-3x text-primary q-mb-lg"></i>
                                <div class="text-h6 adicon-font-1 text-bold q-mb-sm">
                                    Mantenimientos
                                </div>
                                <q-separator
                                    color="secondary"
                                    style="width: 100px; margin: 0 auto; height: 2px"
                                />
                                <div class="text-body adicon-font-1 text-grey-7 q-mt-md">
                                    Nos encargamos de evaluar y supervisar los mantenimientos de los
                                    equipos, minimizar posibles situaciones que atenten con el
                                    funcionamiento y seguridad integral de los equipos.
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3  text-center q-px-md q-my-md">
                            <div class="service-tile">
                                <i class="fas fa-calculator fa-3x text-primary q-mb-lg"></i>
                                <div class="text-h6 adicon-font-1 text-bold q-mb-sm">
                                    Contabilidad
                                </div>
                                <q-separator
                                    color="secondary"
                                    style="width: 100px; margin: 0 auto; height: 2px"
                                />
                                <div class="text-body adicon-font-3 text-grey-7 q-mt-md">
                                    Gestionamos de manera eficiente los recursos financieros del PH.
                                    La elaboración y presupuestos de informe de gastos en conjunto
                                    con la Junta Directiva y el Tesorero para un total control,
                                    transparencia, calidad y cercanía de nuestros servicios.
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3  text-center q-px-md q-my-md">
                            <div class="service-tile">
                                <i class="fas fa-balance-scale fa-3x text-primary q-mb-lg"></i>
                                <div class="text-h6 adicon-font-1 text-bold q-mb-sm">
                                    Legales
                                </div>
                                <q-separator
                                    color="secondary"
                                    style="width: 100px; margin: 0 auto; height: 2px"
                                />
                                <div class="text-body adicon-font-3 text-grey-7 q-mt-md">
                                    Brindamos asesoría legal en temas específicos basados en la
                                    legislación vigente de Propiedad Horizontal.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col desktop-only"></div>
            </div>
        </section>
        <!-- END SERVICES -->

        <!-- MISSION -->
        <div class="row" id="nuestra-mision">
            <div class="col-lg-6 col-md-6 q-py-xl bg-grey-3">
                <div class="row">
                    <div class="col desktop-only"></div>
                    <div class="col-lg-7 q-pa-lg">
                        <div class="text-h4 adicon-font-2 text-bold q-mb-lg">Nuestra Misión</div>
                        <div class="bg-white q-pa-md q-mb-lg shadow-10" style="width: 70%">
                            <q-img :src="require('@/assets/mission.webp')" />
                        </div>
                        <div class="text-h6 adicon-font-2 text-bold q-mb-md">
                            Nuestros principios
                        </div>
                        <div class="text-body adicon-font-1 text-grey-8">
                            Nuestro compromiso es garantizar la gestión integral de la
                            administración total de sus PH. Dándole valor a su inversión, asegurando
                            que su PH sea próspero y acogedor para la tranquilidad de su familia y
                            visitantes.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 q-pa-xl benefits">
                <div style="border: solid 1px #fff" class="q-pa-lg">
                    <div class="row">
                        <div class="col-lg-7">
                            <div class="text-h4 adicon-font-2 text-bold q-mb-lg text-white">
                                Beneficios
                            </div>
                            <div class="text-subtitle2 adicon-font-2 text-bold text-white q-mb-md">
                                Beneficios de nuestra administración
                            </div>
                            <div class="text-body adicon-font-1 text-white q-mb-lg">
                                Nuestra Administración se caracteriza por ser profesional y
                                responsable, presentando siempre la gran disponibilidad para atender
                                y dar soluciones efectivas a nuestros clientes.
                            </div>
                            <div class="text-h6 adicon-font-1 text-white q-mb-lg">
                                <i class="fas fa-check text-secondary on-left"></i>Eficiencia
                            </div>
                            <div class="text-h6 adicon-font-1 text-white q-mb-lg">
                                <i class="fas fa-check text-secondary on-left"></i>Seriedad
                            </div>
                            <div class="text-h6 adicon-font-1 text-white q-mb-lg">
                                <i class="fas fa-check text-secondary on-left"></i>Responsabilidad
                            </div>
                            <div class="text-h6 adicon-font-1 text-white q-mb-lg">
                                <i class="fas fa-check text-secondary on-left"></i>Dinamismo
                            </div>
                            <div class="text-h6 adicon-font-1 text-white q-mb-lg">
                                <i class="fas fa-check text-secondary on-left"></i>Dedicación
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END MISSION -->

        <!-- WHO ARE WE -->
        <div class="row" id="quienes-somos">
            <div class="col-lg-6 col-md-6 q-pa-xl who-are-we">
                <div style="border: solid 1px #fff" class="q-pa-lg">
                    <div class="row">
                        <div class="col"></div>
                        <div class="col-lg-7">
                            <div class="text-h4 adicon-font-2 text-bold q-mb-xl text-white">
                                Quienes Somos
                            </div>
                            <div
                                class="adicon-font-1 text-white q-mb-xl text-justify"
                                style="line-height: 35px; font-size: 15px;"
                            >
                                Somos una compañía especializada en la Administración de Propiedades
                                Horizontales Residenciales y Plazas Comerciales. Dirigida por
                                profesionales experimentados y con experticia en la administración
                                centrada en la atención personalizada, técnica y financiera,
                                cumpliendo con lo establecido en la ley 284 de Propiedad Horizontal
                                garantizándoles que administrar no solo es cobrar mantenimiento y
                                pagar servicios.
                            </div>
                            <q-btn
                                outline
                                label="contáctanos"
                                color="white"
                                class="q-px-md q-py-xs q-mb-xl text-bold adicon-font-2"
                                href="#contacto"
                                v-smooth-scroll
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 q-py-xl bg-grey-3">
                <div class="row">
                    <div style="width: 40px;" class="desktop-only"></div>
                    <div class="col-lg-7 q-pa-lg">
                        <div class="text-h4 adicon-font-2 text-bold q-mb-xl">
                            Atención Personalizada
                        </div>
                        <div class="text-h6 adicon-font-2 text-bold q-mb-md">
                            <i class="far fa-clock text-primary on-left"></i>Horario administrativo
                        </div>
                        <div class="text-body2 adicon-font-1 text-grey-7 q-mb-lg">
                            Brindamos una atención personalizada en un horario administrativo acorde
                            a las necesidades de su PH.
                        </div>
                        <div class="text-h6 adicon-font-2 text-bold q-mb-md">
                            <i class="fas fa-check-double text-primary on-left"></i>Atención 24/7
                        </div>
                        <div class="text-body2 adicon-font-1 text-grey-7 q-mb-lg">
                            En casos de emergencia celular, email o WhatsApp. Estaremos siempre
                            dispuesto atenderles de la manera mas atenta y cordial donde sus
                            necesidades y solicitudes serán atendidas con la debida importancia
                            encontrando pronta solución a su problema.
                        </div>
                        <div class="text-h6 adicon-font-2 text-bold q-mb-md">
                            <i class="far fa-handshake  text-primary on-left"></i>Asistencia y
                            organización
                        </div>
                        <div class="text-body2 adicon-font-1 text-grey-7">
                            Asistencia y organización de las reuniones de Junta directiva y
                            Asambleas de Propietarios Dominio y pleno conocimiento con lo
                            establecido en la ley 284 de Propiedad Horizontal.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END WHO ARE WE -->

        <!-- CONTACT -->
        <section class="q-py-xl" id="contacto">
            <div class="row">
                <div class="col desktop-only"></div>
                <div class="col-lg-7">
                    <div class="row">
                        <div class="col-12 q-pa-md q-mb-md">
                            <div class="text-h4 adicon-font-2 text-bold text-center">
                                Contáctanos
                            </div>
                            <h2 class="hidden">Contactanos</h2>
                        </div>
                        <div class="col-lg-6 col-xs-12 q-pa-md">
                            <q-input
                                square
                                filled
                                label="Nombre"
                                class="adicon-font-1"
                                v-model="contactForm.name"
                            />
                        </div>
                        <div class="col-lg-6 col-xs-12 q-pa-md">
                            <q-input
                                square
                                filled
                                label="Teléfono"
                                class="adicon-font-1"
                                v-model="contactForm.telephone"
                                mask="########"
                            />
                        </div>
                        <div class="col-lg-12 col-xs-12 q-pa-md">
                            <q-input
                                square
                                filled
                                label="Correo electrónico"
                                class="adicon-font-1"
                                v-model="contactForm.email"
                                type="email"
                            />
                        </div>
                        <div class="col-lg-12 col-xs-12 q-pa-md q-mb-md">
                            <q-input
                                square
                                filled
                                label="Mensaje"
                                type="textarea"
                                rows="7"
                                class="adicon-font-1"
                                v-model="contactForm.content"
                            />
                        </div>
                        <q-btn
                            color="primary"
                            label="Enviar"
                            class="adicon-font-2 text-bold q-py-xs q-px-lg"
                            style="margin: 0 auto;"
                            @click="validateContactData()"
                        />
                    </div>
                </div>
                <div class="col desktop-only"></div>
            </div>
            <div class="row"></div>
        </section>

        <q-dialog v-model="contactFormDialog">
            <q-card>
                <q-card-section>
                    <div class="text-h6 text-center">
                        <i class="fas fa-check fa-2x text-primary" v-if="contactSuccess"></i>
                        <i class="fas fa-times fa-2x text-red-7" v-else></i>
                    </div>
                </q-card-section>

                <q-card-section class="q-pt-none">
                    <div class="text-h6 text-bold adicon-font-2 text-center" v-if="!contactSuccess">
                        Porfavor verifica todos los campos.
                    </div>
                    <div class="text-h6 text-bold adicon-font-2 text-center" v-else>
                        Su mensaje ha sido enviado con exito. <br />Pronto nos pondremos en contacto
                        con usted.
                    </div>
                </q-card-section>

                <q-card-actions align="right">
                    <q-btn flat label="Aceptar" color="primary" v-close-popup />
                </q-card-actions>
            </q-card>
        </q-dialog>
        <!-- END CONTACT -->

        <!-- FOOTER -->
        <section>
            <div class="row bg-primary q-py-xl">
                <div class="col desktop-only"></div>
                <div class="col-lg-7 q-pa-md">
                    <div class="row q-mb-lg">
                        <div class="col-lg-4 col-md-4 col-xs-12">
                            <div
                                class="q-mb-md"
                                style="width: 50px; background-color: white; border-radius: 25px; padding: 10px;"
                            >
                                <q-img :src="require('@/assets/logo.webp')" />
                            </div>

                            <div class="text-body2 text-white adicon-font-2 text-bold q-mb-sm">
                                Adicon Panamá
                            </div>
                            <div class="text-body2 text-secondary adicon-font-1 q-mb-md">
                                Nos caracterizamos por la seriedad y responsabilidad, presentando
                                siempre una gran disponibilidad para atender a nuestros clientes.
                            </div>
                        </div>
                        <div class="col"></div>
                        <div class="col-lg-2 col-md-2 col-xs-6">
                            <div class="text-body2 text-white adicon-font-2 text-bold q-mb-sm">
                                Enlaces
                            </div>
                            <a
                                href="#inicio"
                                v-smooth-scroll
                                class="adicon-font-1 text-secondary q-mb-sm"
                                style="text-decoration: none; display: block"
                                >Inicio</a
                            >
                            <a
                                href="#servicios"
                                v-smooth-scroll
                                class="adicon-font-1 text-secondary q-mb-sm"
                                style="text-decoration: none; display: block"
                                >Servicios</a
                            >
                            <a
                                href="#nuestra-mision"
                                v-smooth-scroll
                                class="adicon-font-1 text-secondary q-mb-sm"
                                style="text-decoration: none; display: block"
                                >Nosotros</a
                            >
                            <a
                                href="#contacto"
                                v-smooth-scroll
                                class="adicon-font-1 text-secondary q-mb-sm"
                                style="text-decoration: none; display: block"
                                >Contacto</a
                            >
                            <!-- <router-link
                                to="blog"
                                class="adicon-font-1 text-secondary q-mb-sm"
                                style="text-decoration: none; display: block"
                                >Blog</router-link
                            > -->
                        </div>
                        <div class="col-lg-2 col-md-2 col-xs-6">
                            <div class="text-body2 text-white adicon-font-2 text-bold q-mb-sm">
                                Siguenos
                            </div>
                            <a
                                href="https://www.instagram.com/adiconpanama/"
                                target="_blank"
                                class="adicon-font-1 text-secondary q-mb-sm"
                                style="text-decoration: none; display: block"
                                ><i class="fab fa-instagram "></i>&nbsp;adiconpanama</a
                            >
                            <a
                                href="https://twitter.com/AdiconP"
                                target="_blank"
                                class="adicon-font-1 text-secondary q-mb-sm"
                                style="text-decoration: none; display: block"
                                ><i class="fab fa-twitter "></i>&nbsp;adiconP</a
                            >
                            <a
                                href="https://www.facebook.com/adicon.panama.3"
                                target="_blank"
                                class="adicon-font-1 text-secondary q-mb-sm"
                                style="text-decoration: none; display: block"
                                ><i class="fab fa-facebook "></i>&nbsp;adiconpanama</a
                            >
                        </div>
                        <div class="col-lg-3">
                            <div class="text-body2 text-white adicon-font-2 text-bold q-mb-sm">
                                Contactanos
                            </div>
                            <a
                                href="tel:6823-8190"
                                target="_blank"
                                class="adicon-font-1 text-secondary q-mb-sm"
                                style="text-decoration: none; display: block"
                                ><i class="fas fa-phone-alt "></i>&nbsp;6823-8190</a
                            >
                            <a
                                href="mailto:info@adiconpanama.com"
                                target="_blank"
                                class="adicon-font-1 text-secondary q-mb-sm"
                                style="text-decoration: none; display: block"
                                ><i class="far fa-envelope "></i>&nbsp;info@adiconpanama.com</a
                            >
                            <a
                                href="https://goo.gl/maps/XFsL7dPTCAyPPVir8"
                                target="_blank"
                                class="adicon-font-1 text-secondary q-mb-sm"
                                style="text-decoration: none; display: block"
                                ><i class="fas fa-map-marker-alt"></i>&nbsp;Panamá, Ancón, Edif.
                                Altamira Gardens Torre E #405</a
                            >
                        </div>
                    </div>
                    <div class="row">
                        <q-separator color="secondary" class="q-mb-lg" />
                        <div class="text-body2 adicon-font-1 text-secondary text-center full-width">
                            Copyright &copy; {{ new Date().getFullYear() }} Todos los derechos
                            reservados. Desarrollado por
                            <a href="https://blueballoon.io" target="_blank" class="text-white"
                                >BlueBalloon Inc.</a
                            >
                        </div>
                    </div>
                </div>
                <div class="col desktop-only"></div>
            </div>
        </section>
        <!-- END FOOTER -->
    </q-page>
</template>

<script>
import emailjs from 'emailjs-com'

export default {
    data() {
        return {
            contactForm: {
                name: '',
                telephone: '',
                email: '',
                content: '',
            },
            contactFormDialog: false,
            validEmail: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            contactSuccess: false,
        }
    },
    methods: {
        sendContactEmail() {
            emailjs
                .send('gmail', 'template_iosnibq', this.contactForm, 'user_l9KYZVj8DNvwXi3kegar5')
                .then(
                    result => {
                        console.log('SUCCESS!', result.status, result.text)
                    },
                    error => {
                        console.log('FAILED...', error)
                    }
                )
        },
        validateContactData() {
            if (
                this.contactForm.name.length > 0 ||
                this.contactForm.telephone.length >= 7 ||
                this.contactForm.email.match(this.validEmail) ||
                this.contactForm.content.length > 0
            ) {
                this.sendContactEmail()
                this.contactSuccess = true
                this.contactForm = {
                    name: '',
                    telephone: '',
                    email: '',
                    content: '',
                }
            } else {
                this.contactSuccess = false
            }
            this.contactFormDialog = true
        },
    },
}
</script>

<style>
.adicon-hero {
    background-image: url('../assets/hero.webp');
    background-position: center;
    height: 600px;
}

.service-tile {
    border: solid 1px #e3e3e3;
    padding: 60px 20px;
    min-height: 370px !important;
}
.who-are-we {
    background-image: url('../assets/who_are_we.webp');
    background-position: center center;
    background-size: cover;
}
.benefits {
    background-image: url('../assets/benefits.webp');
    background-position: center center;
    background-size: cover;
}
</style>
