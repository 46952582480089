<template>
    <q-page>
        <section>
            <div class="row">
                <div class="col desktop-only"></div>
                <div class="col-lg-7">
                    <div class="row q-mb-md q-pa-md">
                        <q-img
                            :src="require('@/assets/blog_banner.webp')"
                            class="q-my-lg shadow-9 rounded-borders"
                        />
                        <div class="text-h4 adicon-font-2 text-bold text-center full-width q-my-md">
                            Bienvenido a nuestro blog.
                        </div>
                        <q-separator
                            color="secondary"
                            style="width: 30%; margin: 0 auto; height: 2px"
                        />
                    </div>
                    <div class="row q-mb-xl q-pa-md">
                        <q-card class="full-width">
                            <q-card-section>
                                <div v-for="(blog, i) in reverseBlogs" :key="i">
                                    <div
                                        class="text-h6 adicon-font-2 text-bold text-primary q-my-md"
                                    >
                                        {{ blog.name }}
                                    </div>
                                    <div class="text-body2 adicon-font-1 q-mb-sm">
                                        <span class="text-bold">Publicado: &nbsp;</span>
                                        <span class="text-secondary text-bold">{{
                                            blog.date
                                        }}</span>
                                    </div>
                                    <div
                                        class="text-body2 text-grey-7 q-mb-sm"
                                        v-html="blog.body"
                                        style="height: 80px; overflow: hidden;"
                                    ></div>
                                    <q-btn
                                        label="Leer más"
                                        color="primary"
                                        size="sm"
                                        class="adicon-font-2 text-bold q-mb-md"
                                        @click="selectBlog(i)"
                                    />
                                    <q-separator v-if="i + 1 < blogs.blogs.length" />
                                </div>
                            </q-card-section>
                        </q-card>
                    </div>
                </div>
                <div class="col desktop-only"></div>
            </div>
        </section>

        <q-dialog
            v-model="readBlogDialog"
            persistent
            maximized
            transition-show="slide-down"
            transition-hide="slide-up"
        >
            <q-card dark class="bg-primary text-white adicon-drawer">
                <q-card-section>
                    <div class="row">
                        <q-space />
                        <q-btn flat round icon="fas fa-times" @click="readBlogDialog = false" />
                    </div>
                </q-card-section>
                <q-card-section class="q-mb-xl">
                    <div class="text-h4 adicon-font-2 text-center text-bold">
                        {{ selectedBlog.name }}
                    </div>
                </q-card-section>
                <q-card-section>
                    <div class="row">
                        <div class="col desktop-only"></div>
                        <div class="col-lg-6">
                            <div class="text-subtitle2 adicon-font-2 text-bold q-mb-lg">
                                <span class="text-secondary">Publicado:&nbsp;</span
                                >{{ selectedBlog.date }}
                            </div>
                            <div class="text-body2 adicon-font-1">
                                <div class="text-body2 q-mb-sm" v-html="selectedBlog.body"></div>
                            </div>
                        </div>
                        <div class="col desktop-only"></div>
                    </div>
                </q-card-section>
            </q-card>
        </q-dialog>
    </q-page>
</template>

<script>
export default {
    data() {
        return {
            blogs: require('@/assets/blogs.json'),
            readBlogDialog: false,
            selectedBlog: {},
        }
    },
    methods: {
        selectBlog(index) {
            this.selectedBlog = this.blogs.blogs[index]
            this.readBlogDialog = true
        },
    },
    computed: {
        reverseBlogs() {
            return this.blogs.blogs.reverse() 
        }
    },
}
</script>
