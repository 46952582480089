var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-page',[_c('div',{staticClass:"row q-py-xl"},[_c('div',{staticClass:"col desktop-only"}),_c('div',{staticClass:"col-lg-7"},[_c('q-input',{staticClass:"q-mb-md",attrs:{"label":"Blog name","filled":"","square":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('q-input',{staticClass:"q-mb-md",attrs:{"label":"Date","filled":"","square":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('q-editor',{staticClass:"q-mb-md",attrs:{"dense":_vm.$q.screen.lt.md,"toolbar":[
                    [
                        {
                            label: _vm.$q.lang.editor.align,
                            icon: _vm.$q.iconSet.editor.align,
                            fixedLabel: true,
                            list: 'only-icons',
                            options: ['left', 'center', 'right', 'justify'],
                        } ],
                    ['hr', 'link'],
                    ['fullscreen'],
                    [
                        {
                            label: _vm.$q.lang.editor.formatting,
                            icon: _vm.$q.iconSet.editor.formatting,
                            list: 'no-icons',
                            options: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'code'],
                        },
                        {
                            label: _vm.$q.lang.editor.fontSize,
                            icon: _vm.$q.iconSet.editor.fontSize,
                            fixedLabel: true,
                            fixedIcon: true,
                            list: 'no-icons',
                            options: [
                                'size-1',
                                'size-2',
                                'size-3',
                                'size-4',
                                'size-5',
                                'size-6',
                                'size-7' ],
                        },
                        'removeFormat' ],
                    ['quote', 'unordered', 'ordered', 'outdent', 'indent'],

                    ['undo', 'redo'],
                    ['viewsource'] ]},model:{value:(_vm.body),callback:function ($$v) {_vm.body=$$v},expression:"body"}}),_c('q-btn',{staticClass:"adicon-font-2 text-bold",attrs:{"label":"Parse JSON","color":"primary"},on:{"click":function($event){return _vm.escapeJSON()}}})],1),_c('div',{staticClass:"col desktop-only"})])])}
var staticRenderFns = []

export { render, staticRenderFns }