<template>
    <q-layout view="lHh Lpr lFf">
        <div class="row bg-primary text-white q-pa-sm">
            <div class="col"></div>
            <div class="col-lg-7 col-xs-12">
                <div class="row">
                    <q-space />
                    <q-btn
                        rounded
                        flat
                        dense
                        icon="fab fa-facebook"
                        color="primary"
                        class="text-white on-left"
                        @click="openExternalLink('https://www.facebook.com/adicon.panama.3')"
                    />
                    <q-btn
                        rounded
                        flat
                        dense
                        icon="fab fa-instagram"
                        color="primary"
                        class="text-white on-left"
                        @click="openExternalLink('https://www.instagram.com/adiconpanama/')"
                    />
                    <q-btn
                        rounded
                        flat
                        dense
                        icon="fab fa-twitter"
                        color="primary"
                        class="text-white"
                        @click="openExternalLink('https://twitter.com/AdiconP')"
                    />
                </div>
            </div>
            <div class="col"></div>
        </div>
        <div class="row adicon-font-1">
            <div class="col"></div>
            <div class="col-lg-7 col-sm-12 col-xs-12">
                <div class="row q-pa-md">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-2">
                        <q-img :src="require('@/assets/logo.webp')" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-11 column flex-center desktop-only">
                        <ul
                            class="adicon-navbar full-width"
                            style="text-align: right"
                            v-if="$route.fullPath == '/' || $route.fullPath.includes('/#')"
                        >
                            <li><a href="#inicio" v-smooth-scroll>Inicio</a></li>
                            <li><a href="#servicios" v-smooth-scroll>Servicios</a></li>
                            <li><a href="#nuestra-mision" v-smooth-scroll>Nosotros</a></li>
                            <li><a href="#contacto" v-smooth-scroll>Contacto</a></li>
                            <!-- <li><router-link to="/blog">Blog</router-link></li> -->
                        </ul>
                        <ul class="adicon-navbar full-width" style="text-align: right" v-else>
                            <router-link to="/"
                                ><i class="fas fa-arrow-left on-left" />Volver al
                                inicio</router-link
                            >
                        </ul>
                    </div>
                    <q-space />
                    <div
                        class="col-xs-1 column flex-center mobile-only"
                        v-if="$route.fullPath == '/' || $route.fullPath.includes('/#')"
                    >
                        <q-btn
                            flat
                            rounded
                            dense
                            class="text-primary"
                            icon="fas fa-bars"
                            @click="mobileNavDrawer = true"
                        />
                    </div>
                    <div class="col-xs-1 column flex-center mobile-only" v-else>
                        <q-btn flat rounded dense class="text-primary" icon="fas fa-home" to="/" />
                    </div>
                </div>
            </div>
            <div class="col"></div>
        </div>
        <q-page-container>
            <router-view />
            <!-- FLOATING ACTION BTN -->
            <q-page-sticky position="bottom-right" :offset="[18, 18]">
                <q-btn
                    fab
                    icon="fab fa-whatsapp"
                    color="primary"
                    @click="openExternalLink('https://wa.me/50768238190')"
                />
            </q-page-sticky>
            <!-- END FLOATING ACION BTN -->
        </q-page-container>
        <q-dialog
            v-model="mobileNavDrawer"
            persistent
            maximized
            transition-show="slide-left"
            transition-hide="slide-right"
        >
            <q-card dark class="bg-primary text-white adicon-drawer">
                <q-card-section>
                    <div class="row">
                        <q-space />
                        <q-btn flat round icon="fas fa-times" @click="mobileNavDrawer = false" />
                    </div>
                </q-card-section>
                <q-card-section>
                    <a
                        href="#inicio"
                        @click="mobileNavDrawer = false"
                        class="adicon-drawer-link adicon-font-1 text-bold q-mb-md"
                        >Inicio</a
                    >
                    <a
                        href="#servicios"
                        @click="mobileNavDrawer = false"
                        class="adicon-drawer-link adicon-font-1 text-bold q-mb-md"
                        >Servicios</a
                    >
                    <a
                        href="#nuestra-mision"
                        @click="mobileNavDrawer = false"
                        class="adicon-drawer-link adicon-font-1 text-bold q-mb-md"
                        >Nosotros</a
                    >
                    <a
                        href="#contacto"
                        @click="mobileNavDrawer = false"
                        class="adicon-drawer-link adicon-font-1 text-bold q-mb-md"
                        >Contacto</a
                    >
                    <!-- <a
                        class="adicon-drawer-link adicon-font-1 text-bold q-mb-md"
                        @click="
                            mobileNavDrawer = false
                            $router.push('/blog')
                        "
                        >Blog</a
                    > -->

                    <div class="row">
                        <q-space />
                        <q-btn
                            rounded
                            flat
                            dense
                            icon="fab fa-facebook"
                            color="primary"
                            class="text-white on-left"
                            size="lg"
                            @click="openExternalLink('https://www.facebook.com/adicon.panama.3')"
                        />
                        <q-btn
                            rounded
                            flat
                            dense
                            icon="fab fa-instagram"
                            color="primary"
                            class="text-white on-left"
                            size="lg"
                            @click="openExternalLink('https://www.instagram.com/adiconpanama/')"
                        />
                        <q-btn
                            rounded
                            flat
                            dense
                            icon="fab fa-twitter"
                            color="primary"
                            class="text-white"
                            size="lg"
                            @click="openExternalLink('https://twitter.com/AdiconP')"
                        />
                    </div>
                </q-card-section>
            </q-card>
        </q-dialog>
    </q-layout>
</template>

<script>
export default {
    data() {
        return {
            mobileNavDrawer: false,
        }
    },
    methods: {
        openExternalLink(url) {
            window.open(url, '_blank')
        },
    },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&family=Ubuntu:wght@400;500;700&display=swap');
.adicon-font-1 {
    font-family: 'Ubuntu', sans-serif;
}
.adicon-font-2 {
    font-family: 'Arvo', serif;
}
.adicon-navbar {
    text-align: left;
    margin: 0;
}
.adicon-navbar li {
    display: inline-block;
    margin-left: 45px;
}
.adicon-navbar a {
    text-decoration: none;
    font-size: 18px;
    color: #1b5f5e;
    font-weight: 500;
}
.adicon-navbar a:hover {
    color: #b1cfc5;
}

.adicon-drawer a {
    display: block;
    color: #fff;
    text-decoration: none;
    font-size: 35px;
    text-align: right;
}
</style>
